

















































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash'
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
// @ts-ignore
import { Api, HttpResponseError, TableQuery } from '../api'
import { AllLegacyAvatarItemKeys, AvatarPurchaseInfo, LegacyAvatarAccessory, LegacyAvatarBackgroundType, LegacyAvatarColor, LegacyAvatarEyeColor, LegacyAvatarEyeType, LegacyAvatarFace, LegacyAvatarHairColor, LegacyAvatarHairType, LegacyAvatarMouthType, LegacyAvatarShirtType, LegacyAvatarSkinColor } from '../api/types/avatar'
import ModalMixin from '../mixins/ModalMixin'
import { AvatarColor, AvatarColors, avatarColors, AvatarColorSwatch, AvatarItemInfo, exampleAvatarItems, testAvatarData, UserAvatar, AvatarItemForShop, defaultAvatarItems, UserAvatarOptions } from './avatarItems'
import { AvatarRenderer, CanvasForUseInAvatarRendering } from './avatarRenderer'
import AvatarShopItem from './AvatarShopItem.vue'
import { convertToLegacyAvatar } from './avatarUtils'
import AvatarView from './AvatarView.vue'

interface AvatarShopColorSet {
  title: string,
  key: string,
  options: AvatarColorSwatch[]
}

@Component({
  components: {
    AvatarShopItem,
    AvatarView
  }
})
export default class AvatarShop extends Mixins(ModalMixin) {
  @Prop({ required: false }) data!: UserAvatar
  @Prop({ type: Boolean, default: false }) modal!: boolean
  @Prop({ type: Boolean, default: false }) allowDebug!: boolean
  @Prop({ type: Boolean, default: false }) previewOnly!: boolean

  @Watch('data') private onAvatarDataChange () {
    this.avatar = _.cloneDeep(this.data)
    this.originalAvatar = _.cloneDeep(this.avatar)
    this.validateEquippedItems()
    this.updateAvatarSrc()
  }

  private originalAvatar: UserAvatar = testAvatarData
  private avatar: UserAvatar = testAvatarData

  private debug = false // Debug mode treats all items as being owned but does not bypass purchase validation
  private legacyTesting = false

  private avatarRenderer: AvatarRenderer | null = null
  private avatarSrc = ''
  private purchasePreviewAvatarSrc = ''
  private updateKey = 0
  private legacyAvatarRefresh = 0

  private allExtraItemKeys: NonNullable<UserAvatarOptions['extra']> = {} // used to cater the update payload - updated _ONLY_ when retrieving items from the API

  private purchaseData: AvatarPurchaseInfo[] = []
  private avatarItems: AvatarItemForShop[] = []
  private avatarColorSets: AvatarColors = avatarColors

  private itemBeingPurchased: AvatarItemInfo | null = null

  // Likely not used
  private tableQuery: TableQuery = {
    dir: 'asc',
    sort: 'cost',
    take: 10,
    skip: 0,
    term: ''
  }

  private selectedCategory = 'face'
  private categories: { [key: string]: string } = {
    face: 'Face',
    hair: 'Hair',
    headwear: 'Headwear',
    tops: 'Tops',
    accessories: 'Accessories',
    pets: 'Pets',
    extra: 'Extra',
    background: 'Background'
  }

  async mounted () {
    const newCanvas = () => {
      const c = document.createElement('canvas') as HTMLCanvasElement
      const context = c.getContext('2d', { willReadFrequently: true })! as CanvasRenderingContext2D
      const canvas = c as CanvasForUseInAvatarRendering
      return { canvas, context }
    }

    const newImage = () => {
      return new Image()
    }
    this.avatarRenderer = new AvatarRenderer(newCanvas, newImage)
    
    if (this.data) {
      this.avatar = _.cloneDeep(this.data)
      this.originalAvatar = _.cloneDeep(this.avatar)
      this.updateAvatarSrc()
    } else {
      this.getAvatarData()
    }
    this.getAvatarPurchases()
    this.getAvatarItems()
  }

  get isSuperUser () {
    if (!this.$store.state) {
      return false
    }
    if (!this.$store.state.user) {
      return false
    }
    if (!this.$store.state.user.superuser) {
      return false
    }
    return true
  }

  get canDebug () {
    return this.isSuperUser && this.allowDebug
  }

// REMOVE LEGACY STUFF WHEN THIS GOES LIVE
  get legacyAvatar () {
    return convertToLegacyAvatar(this.avatar)
  }

  get avatarItemsFiltered () {
    if (this.selectedCategory === 'face') {
      return this.avatarItems.filter(x => x.item.type === 'eyes' || x.item.type === 'mouth' || x.item.type === 'facepaint')
    }
    if (this.selectedCategory === 'hair') {
      return this.avatarItems.filter(x => x.item.type === 'hair' || x.item.type === 'facialhair')
    }
    if (this.selectedCategory === 'headwear') {
      return this.avatarItems.filter(x => x.item.type === 'face' || x.item.type === 'hat')
    }
    if (this.selectedCategory === 'tops') {
      return this.avatarItems.filter(x => x.item.type === 'shirt')
    }
    if (this.selectedCategory === 'accessories') {
      return this.avatarItems.filter(x => x.item.type === 'scarf' || x.item.type === 'hand' || x.item.type === 'jewellery' || x.item.type === 'wings')
    }
    if (this.selectedCategory === 'pets') {
      return this.avatarItems.filter(x => x.item.type === 'pet')
    }
    if (this.selectedCategory === 'extra') {
      return this.avatarItems.filter(x => x.item.type === 'extra')
    }
    if (this.selectedCategory === 'background') {
      return this.avatarItems.filter(x => x.item.type === 'background')
    }
    return this.avatarItems
  }

  get avatarItemPages () {
    const sets: { [key: string]: { [key: string]: AvatarItemForShop[] } } = {}
    sets.face = { eyes: this.avatarItemSets.eyes, mouth: this.avatarItemSets.mouth, facepaint: this.avatarItemSets.facepaint }
    sets.hair = { hair: this.avatarItemSets.hair, facialhair: this.avatarItemSets.facialhair }
    sets.headwear = { face: this.avatarItemSets.face, hat: this.avatarItemSets.hat }
    sets.tops = { shirt: this.avatarItemSets.shirt }
    sets.accessories = { scarf: this.avatarItemSets.scarf, hand: this.avatarItemSets.hand, jewellery: this.avatarItemSets.jewellery, wings: this.avatarItemSets.wings }
    sets.pets = { pet: this.avatarItemSets.pet }
    sets.extra = { extra: this.avatarItemSets.extra }
    sets.background = { background: this.avatarItemSets.background }

    return sets
  }

  get avatarItemSets () {
    const result: { [key: string]: AvatarItemForShop[] } = {}
    this.avatarItems.forEach((x) => {
      const type = x.item.type
      if (!result[type]) {
        result[type] = []
      }
      result[type].push(x)
    })
    return result
  }

  get avatarItemSetsOwned () {
    const result: { [key: string]: AvatarItemForShop[] } = {}
    this.avatarItems.forEach((x) => {
      if ((!x.owned && !!x.item.cost) && !this.debug) { return }
      const type = x.item.type
      if (!result[type]) {
        result[type] = []
      }
      result[type].push(x)
    })
    return result
  }

  get isActive () {
    return !!this.$store.state.user && !!this.avatar && !!this.avatarRenderer
  }

  get previewSrc (): string {
    return this.avatarSrc || (this.$store.state.user && `https://files.edshed.com/production/avatars/${this.$store.state.user.avatar_hash}.png`)
  }

  get purchasePreviewSrc () {
    return this.purchasePreviewAvatarSrc || 'https://files.edshed.com/img/avatar/avatar_loading.png'
  }

  get baseColorSelections (): AvatarShopColorSet[] {
    return [
      { title: `Skin ${this.$t('Colour')}`, key: 'skinColor', options: this.avatarColorSets.skinColors },
      { title: `Eye ${this.$t('Colour')}`, key: 'eyeColor', options: this.avatarColorSets.eyeColors }
    ]
  }

  get currentColorSelections (): AvatarShopColorSet[] {
    if (this.selectedCategory === 'hair') {
      return [{ title: `Hair ${this.$t('Colour')}`, key: 'hairColor', options: this.avatarColorSets.hairColors }]
    }
    if (this.selectedCategory === 'headwear') {
      return [{ title: `Headwear ${this.$t('Colour')}`, key: 'headwearColor', options: this.avatarColorSets.customColors }]
    }
    if (this.selectedCategory === 'background') {
      return [{ title: `Background ${this.$t('Colour')}`, key: 'backgroundColor', options: this.avatarColorSets.customColors }]
    }
    if (this.selectedCategory === 'tops') {
      return [
        { title: `Main Shirt ${this.$t('Colour')}`, key: 'shirtColor1', options: this.avatarColorSets.customColors },
        { title: `Secondary Shirt ${this.$t('Colour')}`, key: 'shirtColor2', options: this.avatarColorSets.customColors }
      ]
    }
    return []
  }

  private async updateAvatarSrc () {
    this.avatarRenderer!.import(this.avatar)
    const canvas = await this.avatarRenderer!.getAvatarImageURL()
    this.avatarSrc = canvas.toDataURL()
    this.legacyAvatarRefresh++

    // this.avatarSrc = await this.avatarRenderer.getAvatarImageURL()
  }

  private async generateItemPreview () {
    if (!this.itemBeingPurchased) { return }
    this.avatarRenderer!.import(this.avatar)
    this.avatarRenderer!.addItem(this.itemBeingPurchased)
    const canvas = await this.avatarRenderer!.getAvatarImageURL()
    this.purchasePreviewAvatarSrc = canvas.toDataURL()
  }

  private async getAvatarItems () {
    try {
      const data = await Api.browseAvatarItems({})
      if (data) {
        // const missingstuff: string[] = [];
        // (AllLegacyAvatarItemKeys as string[]).forEach((x) => {
        //   if (x === 'wide' || x === 'plain') { return }
        //   if (!data.items.find(y => y.key === x)) {
        //     missingstuff.push(x)
        //   }
        // })
        // console.log(missingstuff)

        data.items.forEach((item) => {
          if (item.type === 'extra') {
            this.allExtraItemKeys[item.key] = null
          }
          if (!this.avatarItems.find(x => x.item.id === item.id)) {
            const equipped = (this.avatar as any)[item.type] === item.key
            const purchased = !!this.purchaseData.find(x => x.key === item.key)
            const owned = this.debug || item.cost <= 0 || purchased
            this.avatarItems.push({
              item,
              equipped,
              owned,
              purchased
            })
          }
        })
      }
      this.validateDefaultItems()
      this.validateEquippedItems()
      return
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }

  private validateDefaultItems () {
    if (!this.avatarItems.length) { return }
    const hair = this.avatarItems.find(x => x.item.type === 'hair' && x.equipped)
    const mouth = this.avatarItems.find(x => x.item.type === 'mouth' && x.equipped)
    const eyes = this.avatarItems.find(x => x.item.type === 'eyes' && x.equipped)
    const shirt = this.avatarItems.find(x => x.item.type === 'shirt' && x.equipped)
    const background = this.avatarItems.find(x => x.item.type === 'background' && x.equipped)

    if (!hair) {
      const defaultHair = this.avatarItems.find(x => x.item.key === defaultAvatarItems.hair)
      if (defaultHair) { defaultHair.equipped = true }
    }
    if (!mouth) {
      const defaultMouth = this.avatarItems.find(x => x.item.key === defaultAvatarItems.mouth)
      if (defaultMouth) { defaultMouth.equipped = true }
    }
    if (!eyes) {
      const defaultEyes = this.avatarItems.find(x => x.item.key === defaultAvatarItems.eyes)
      if (defaultEyes) { defaultEyes.equipped = true }
    }
    if (!shirt) {
      const defaultShirt = this.avatarItems.find(x => x.item.key === defaultAvatarItems.shirt)
      if (defaultShirt) { defaultShirt.equipped = true }
    }
    if (!background) {
      const defaultBackground = this.avatarItems.find(x => x.item.key === defaultAvatarItems.background)
      if (defaultBackground) { defaultBackground.equipped = true }
    }
  }

  private validateEquippedItems () {
    this.avatarItems.forEach((item) => {
      const type = item.item.type
      const key = item.item.key
      if (this.purchaseData.find(x => x.key === key)) {
        item.purchased = true
      }
      if (this.debug || item.item.cost === 0 || item.purchased) {
        item.owned = true
      } else {
        item.owned = false
      }
      if (type !== 'default' && type !== 'extra') {
        const itemInCurrentSlot = this.avatar[type]
        if (itemInCurrentSlot && itemInCurrentSlot.key === key) {
          item.equipped = true
        } else {
          item.equipped = false
        }
      } else if (type === 'extra') {
        const extra = this.avatar['extra']
        if (extra && Object.keys(extra).includes(key)) {
          item.equipped = true
        } else {
          item.equipped = false
        }
      }
    })
  }

  private async getAvatarPurchases () {
    try {
      this.purchaseData = await Api.getAvatarPurchases()
      return
    } catch (err) {
      if (err instanceof Error) {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000
        })
      }
    }
  }

  async getAvatarData () {
    try {
      this.avatar = await Api.getUserAvatarInfo(this.$store.state.user.id)
      this.originalAvatar = _.cloneDeep(this.avatar)
      // console.log(this.avatar)
      this.updateAvatarSrc()
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }

  private toggleDebug () {
    this.debug = !this.debug
    if (!this.debug) {
      this.getAvatarData()
      this.legacyTesting = false
    }
    this.validateEquippedItems()
  }

  private toggleLegacy () {
    this.debug = true
    this.legacyTesting = !this.legacyTesting
    this.validateEquippedItems()
  }

  private changeCategory (name: string) {
    this.selectedCategory = name
  }

  private setColor (key: AvatarColor, color: string) {
    if (key === 'skinColor') {
      this.avatar.skinColor = color as LegacyAvatarSkinColor
    }
    if (key === 'eyeColor') {
      this.avatar.eyeColor = color as LegacyAvatarEyeColor
    }
    if (key === 'hairColor') {
      this.avatar.hairColor = color as LegacyAvatarHairColor
    }
    if (key === 'shirtColor1') {
      this.avatar.shirtColor1 = color as LegacyAvatarColor
    }
    if (key === 'shirtColor2') {
      this.avatar.shirtColor2 = color as LegacyAvatarColor
    }
    if (key === 'headwearColor') {
      this.avatar.headwearColor = color as LegacyAvatarColor
    }
    if (key === 'backgroundColor') {
      this.avatar.backgroundColor = color as LegacyAvatarColor
    }

    const elem = this.$refs.preview as HTMLElement
    if (elem && !!('Animation' in window)) {
      elem.animate([
        { transform: 'rotate(0deg)', transformOrigin: 'center', offset: 0, easing: 'ease-in-out' },
        { transform: 'rotate(3deg)', offset: 0.3, easing: 'ease-in-out' },
        { transform: 'rotate(-2deg)', offset: 0.7, easing: 'ease-in-out' },
        { transform: 'rotate(1deg)', offset: 0.9, easing: 'ease-in-out' },
        { transform: 'rotate(-1deg)', offset: 0.95, easing: 'ease-in-out' },
        { transform: 'rotate(0deg)', transformOrigin: 'center', offset: 1, easing: 'ease-out' }
      ], {
        duration: 300
      })
    }
    this.updateAvatarSrc()
    this.updateItemsUsingColor(key)
  }

  private updateItemsUsingColor (key: AvatarColor) {
    const items = this.$refs.items as AvatarShopItem[]
    if (items && items.length) {
      const targets = items.filter(x => x.item && !!x.item.item.layers.layers.filter(y => y.colors.includes(key)).length)
      targets.forEach(x => x.updateImage())
    }
  }

  private updateAllColourableItems () {
    const items = this.$refs.items as AvatarShopItem[]
    if (items && items.length) {
      const targets = items.filter(x => x.item && !!x.item.item.layers.layers.filter(y => !!y.colors.length).length)
      targets.forEach(x => x.updateImage())
    }
  }

  private onAvatarItemClick (item: AvatarItemForShop) {
    // this.equipItem(item.item)
    if (item.owned && !item.equipped) {
      // if (!item.equipped) {
      this.equipItem(item.item)
    } else if (item.equipped) {
      this.unequipItem(item.item)
    } else {
      this.previewItem(item.item)
    }
  }

  private equipItem (item: AvatarItemInfo) {
    if (item.type === 'extra') {
      if (this.avatar.extra) {
        this.avatar.extra[item.key] = item
      } else {
        this.avatar.extra = {}
        this.avatar.extra[item.key] = item
      }
    } else {
      const others = this.avatarItems.filter(x => x.item.type === item.type && x.item.id !== item.id)
      others.forEach((x) => {
        x.equipped = false
      });

      (this.avatar as any)[item.type] = item
    }
    const shopItem = this.avatarItems.find(x => x.item.id === item.id)
    if (shopItem) {
      shopItem.equipped = true
    }

    const elem = this.$refs.preview as HTMLElement
    if (elem && !!('Animation' in window)) {
      // elem.animate([
      //   { transform: 'rotate(0deg)', offset: 0, easing: 'ease-in-out' },
      //   { transform: 'rotate(7deg)', offset: 0.3, easing: 'ease-in-out' },
      //   { transform: 'rotate(-5deg)', offset: 0.7, easing: 'ease-in-out' },
      //   { transform: 'rotate(4deg)', offset: 0.9, easing: 'ease-in-out' },
      //   { transform: 'rotate(-2deg)', offset: 0.95, easing: 'ease-in-out' },
      //   { transform: 'rotate(0deg)', offset: 1, easing: 'ease-out' }
      // ], {
      //   duration: 200
      // })
      elem.animate([
        { transform: 'scaleX(1) scaleY(1)', offset: 0, easing: 'ease-out' },
        { transform: 'scaleX(1.1) scaleY(0.9)', offset: 0.4, easing: 'ease-out' },
        { transform: 'scaleX(0.9) scaleY(1.05)', offset: 0.6, easing: 'ease-in-out' },
        { transform: 'scaleX(1.05) scaleY(0.98)', offset: 0.8, easing: 'ease-in-out' },
        { transform: 'scaleX(1) scaleY(1)', offset: 1, easing: 'ease-in-out' }
      ], {
        duration: 300
      })
    }

    this.updateAvatarSrc()
  }

  private unequipItem (item: AvatarItemInfo) {
    if (!['shirt', 'hair', 'mouth', 'eyes', 'background'].includes(item.type)) {
      if (item.type === 'extra') {
        const shopItem = this.avatarItems.find(x => item.id === x.item.id)
        if (shopItem) {
          shopItem.equipped = false
        }
        if (this.avatar.extra) {
          delete this.avatar.extra[item.key]
        } else {
          this.avatar.extra = {}
        }
      } else {
        const shopItem = this.avatarItems.find(x => x.item.id === item.id)
        if (shopItem) {
          shopItem.equipped = false
        }
        (this.avatar as any)[item.type] = null
      }
      this.avatarRenderer!.removeItem(item)

      const elem = this.$refs.preview as HTMLElement
      if (elem && !!('Animation' in window)) {
        // elem.animate([
        //   { transform: 'rotate(0deg)', offset: 0, easing: 'ease-in-out' },
        //   { transform: 'rotate(7deg)', offset: 0.3, easing: 'ease-in-out' },
        //   { transform: 'rotate(-5deg)', offset: 0.7, easing: 'ease-in-out' },
        //   { transform: 'rotate(4deg)', offset: 0.9, easing: 'ease-in-out' },
        //   { transform: 'rotate(-2deg)', offset: 0.95, easing: 'ease-in-out' },
        //   { transform: 'rotate(0deg)', offset: 1, easing: 'ease-out' }
        // ], {
        //   duration: 200
        // })
        elem.animate([
          { transform: 'scaleX(1) scaleY(1)', offset: 0, easing: 'ease-in' },
          { transform: 'scaleX(0.9) scaleY(1.05)', offset: 0.6, easing: 'ease-in' },
          { transform: 'scaleX(1.05) scaleY(0.95)', offset: 0.9, easing: 'ease-in-out' },
          { transform: 'scaleX(1) scaleY(1)', offset: 1, easing: 'ease-in-out' }
        ], {
          duration: 200
        })
      }

      this.updateAvatarSrc()
    }
  }

  private previewItem (item: AvatarItemInfo) {
    this.purchasePreviewAvatarSrc = ''
    this.itemBeingPurchased = item
    this.generateItemPreview()
  }

  private async purchaseItem () {
    if (this.itemBeingPurchased) {
      try {
        // Endpoint needs changing to just accept the item key if they are to be unique
        //@ts-ignore
        const data = await Api.purchaseAvatarItem(this.itemBeingPurchased.type, this.itemBeingPurchased.key)
        // const data = await { items: this.purchaseData, user: {} }
        this.purchaseData = data.items
        const itemInShop = this.avatarItems.find(x => x.item.id === this.itemBeingPurchased!.id)
        if (itemInShop) {
          itemInShop.owned = true
          itemInShop.purchased = true
        }
        this.equipItem(this.itemBeingPurchased)
        this.itemBeingPurchased = null
      } catch (error: unknown) {
        if (error instanceof Error) {
          this.alert({ title: error.name, message: error.message, console: error.stack })
        }
      }
    } else {
      this.cancelPurchase()
    }
  }

  private cancelPurchase () {
    this.itemBeingPurchased = null
  }

  private async closeModal () {
    if (!this.previewOnly && this.checkForChanges()) {
      if (await this.confirm({ title: 'Discard Changes?', message: 'Changes to your avatar will be lost, but purchases made with 🍯 Honeypots will not be lost.' })) {
        this.$emit('close')
      }
    } else {
      this.$emit('close')
    }
  }

  private checkForChanges () {
    // Get all keys used between the old and new avatar
    const keys = _.uniq([...Object.keys(this.avatar), ...Object.keys(this.originalAvatar)])
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      //@ts-ignore
      const curValue = this.avatar[key] as any
      //@ts-ignore
      const oldValue = this.originalAvatar[key] as any

      // if the values are the same, don't bother validating
      if (curValue === oldValue) { continue }

      // If one is truthy and the other falsey then there's been a change
      if (!!curValue && !oldValue) { return true }
      if (!curValue && !!oldValue) { return true }

      // If both are falsey then don't bother validating and continue
      if (!curValue && !oldValue) { continue }

      // if property is a color string
      if (key.toLowerCase().includes('color')) {
        if (curValue !== oldValue) { return true }
      } else if (key === 'extra') {
        // Check for all the item keys in the extra objects
        if (!curValue || !oldValue) { return true }
        const curExtraKeys = Object.keys(curValue)
        const oldExtraKeys= Object.keys(oldValue)
        if (curExtraKeys.length !== oldExtraKeys.length ) { return true }
        for (let j = 0; j < curExtraKeys.length; j++) {
          const extraKey = curExtraKeys[j]
          const curExtraItem = curValue[extraKey]
          const oldExtraItem = oldValue[extraKey]
          if (!curExtraItem || !oldExtraItem) { return true }
          if (curExtraItem.key !== oldExtraItem.key) { return true }
        }

      } else {
        // Otherwise at this point, assume both are item objects
        const curKey = curValue.key
        const oldKey = oldValue.key
        if (!curKey || !oldKey) { return true }
        if (curKey !== oldKey) { return true }
      }
    }
    // Everything's the same, no unsaved changes
    return false
  }

  private async saveAvatar () {
    const payload: UserAvatarOptions = {}
    const extra = Object.assign({}, this.allExtraItemKeys)
    Object.entries(this.avatar).forEach(([key, value]) => {
      if (value === undefined || value === null || value === '') {
        // @ts-ignore
        payload[key] = undefined
      }
      else if (key === 'extra') {
        Object.entries(value).forEach(([extraKey, extraValue]) => {
          extra[extraKey] = true
        })
        payload['extra'] = extra
      } else if (key.toLowerCase().includes('color')) {
        // @ts-ignore
        payload[key] = value
      } else {
        // console.log(key, value)
        // @ts-ignore
        payload[key] = value.key
      }
    })
    console.log(payload)
    try {
      const data = await Api.saveAvatar(payload)
      if (data) {
        this.$store.commit('SET_USER', data.user)
        this.$buefy.toast.open({
          message: 'Avatar Saved',
          type: 'is-success',
          position: 'is-bottom',
          duration: 2000
        })
        this.$emit('save', this.avatar)
        this.originalAvatar = _.cloneDeep(this.avatar)
        if (window.localStorage) {
          window.localStorage.setItem('user', JSON.stringify(data.user))
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }


    
  }

  private downloadAvatar () {
    const link = document.createElement('a') as HTMLAnchorElement
    link.download = 'avatar.png'
    link.href = this.previewSrc
    link.click()
  }

  private randomiseAvatar () {

    const availableItems = {...this.avatarItemSetsOwned}
    
    if (availableItems.hat) {
      // Filter out religious head dresses just in case
      availableItems.hat = availableItems.hat.filter(x => ![
        'head_covering_16',
        'head_covering_13',
        'head_covering_10',
        'head_covering_6',
        'head_covering_2',
        'turban_2',
        'hatkippah',
      ].includes(x.item.key))
    }
    if (this.legacyTesting && this.debug) {
      //@ts-ignore
      availableItems.hair = [...availableItems.hair].filter(x => LegacyAvatarHairType.includes(x.item.key))
      availableItems.eyes = [...availableItems.eyes].filter(x => [...LegacyAvatarEyeType, 'wide_eyes'].includes(x.item.key))
      //@ts-ignore
      availableItems.mouth = [...availableItems.mouth].filter(x => LegacyAvatarMouthType.includes(x.item.key))
      availableItems.background = [...availableItems.background].filter(x => [...LegacyAvatarBackgroundType, 'plain_background'].includes(x.item.key))
      availableItems.shirt = [...availableItems.shirt].filter(x => [...LegacyAvatarShirtType, 'plain_shirt', 'wide_shirt'].includes(x.item.key))
      //@ts-ignore
      availableItems.facialhair = [...availableItems.facialhair].filter(x => [...LegacyAvatarFace, ...LegacyAvatarAccessory].includes(x.item.key))
      //@ts-ignore
      availableItems.facepaint = [...availableItems.facepaint].filter(x => [...LegacyAvatarFace, ...LegacyAvatarAccessory].includes(x.item.key))
      //@ts-ignore
      availableItems.hat = [...availableItems.hat].filter(x => [...LegacyAvatarFace, ...LegacyAvatarAccessory].includes(x.item.key))
      //@ts-ignore
      availableItems.hand = [...availableItems.hand].filter(x => [...LegacyAvatarFace, ...LegacyAvatarAccessory].includes(x.item.key))
      //@ts-ignore
      availableItems.jewellery = [...availableItems.jewellery].filter(x => [...LegacyAvatarFace, ...LegacyAvatarAccessory].includes(x.item.key))
      //@ts-ignore
      availableItems.wings = [...availableItems.wings].filter(x => [...LegacyAvatarFace, ...LegacyAvatarAccessory].includes(x.item.key))
      //@ts-ignore
      availableItems.pet = [...availableItems.pet].filter(x => [...LegacyAvatarFace, ...LegacyAvatarAccessory].includes(x.item.key))
      //@ts-ignore
      availableItems.scarf = [...availableItems.scarf].filter(x => [...LegacyAvatarFace, ...LegacyAvatarAccessory].includes(x.item.key))
    }

    


    const hairColor = LegacyAvatarHairColor[Math.floor(Math.random() * LegacyAvatarHairColor.length)]
    const shirtColor1 = LegacyAvatarColor[Math.floor(Math.random() * LegacyAvatarColor.length)]
    const shirtColor2 = LegacyAvatarColor[Math.floor(Math.random() * LegacyAvatarColor.length)]
    const headwearColor = LegacyAvatarColor[Math.floor(Math.random() * LegacyAvatarColor.length)]
    const backgroundColor = LegacyAvatarColor[Math.floor(Math.random() * LegacyAvatarColor.length)]



    const hair = availableItems.hair[Math.floor(Math.random() * availableItems.hair.length)]
    const eyes = availableItems.eyes[Math.floor(Math.random() * availableItems.eyes.length)]
    const mouth = availableItems.mouth[Math.floor(Math.random() * availableItems.mouth.length)]
    const background = availableItems.background[Math.floor(Math.random() * availableItems.background.length)]
    const shirt = availableItems.shirt[Math.floor(Math.random() * availableItems.shirt.length)]

    let facialhair = Math.random() > 0.8 && availableItems.facialhair ? availableItems.facialhair[Math.floor(Math.random() * availableItems.facialhair.length)] : null
    let facepaint = Math.random() > 0.8 && availableItems.facepaint ? availableItems.facepaint[Math.floor(Math.random() * availableItems.facepaint.length)] : null
    let face = Math.random() > 0.5 && availableItems.face ? availableItems.face[Math.floor(Math.random() * availableItems.face.length)] : null
    let hat = Math.random() > 0.3 && availableItems.hat ? availableItems.hat[Math.floor(Math.random() * availableItems.hat.length)] : null
    let hand = Math.random() > 0.3 && availableItems.hand ? availableItems.hand[Math.floor(Math.random() * availableItems.hand.length)] : null
    let jewellery = Math.random() > 0.3 && availableItems.jewellery ? availableItems.jewellery[Math.floor(Math.random() * availableItems.jewellery.length)] : null
    let wings = Math.random() > 0.5 && availableItems.wings ? availableItems.wings[Math.floor(Math.random() * availableItems.wings.length)] : null
    let pet = Math.random() > 0.3 && availableItems.pet ? availableItems.pet[Math.floor(Math.random() * availableItems.pet.length)] : null
    let scarf = Math.random() > 0.5 && availableItems.scarf ? availableItems.scarf[Math.floor(Math.random() * availableItems.scarf.length)] : null

    if (this.legacyTesting && this.debug) {
      let faceFound = false
      let accessoryFound = false
      if (facialhair) {
        //@ts-ignore
        if (LegacyAvatarFace.includes(facialhair.item.key)) { 
          if (faceFound) {
            facialhair = null
          } else {
            faceFound = true
          }
        }
        //@ts-ignore
        else if (LegacyAvatarAccessory.includes(facialhair.item.key)) { 
          if (accessoryFound) {
            facialhair = null
          } else {
            accessoryFound = true
          }
        }
      }
      if (facepaint) {
        //@ts-ignore
        if (LegacyAvatarFace.includes(facepaint.item.key)) { 
          if (faceFound) {
            facepaint = null
          } else {
            faceFound = true
          }
        }
        //@ts-ignore
        else if (LegacyAvatarAccessory.includes(facepaint.item.key)) { 
          if (accessoryFound) {
            facepaint = null
          } else {
            accessoryFound = true
          }
        }
      }
      if (face) {
        //@ts-ignore
        if (LegacyAvatarFace.includes(face.item.key)) { 
          if (faceFound) {
            face = null
          } else {
            faceFound = true
          }
        }
        //@ts-ignore
        else if (LegacyAvatarAccessory.includes(face.item.key)) { 
          if (accessoryFound) {
            face = null
          } else {
            accessoryFound = true
          }
        }
      }
      if (hat) {
        //@ts-ignore
        if (LegacyAvatarFace.includes(hat.item.key)) { 
          if (faceFound) {
            hat = null
          } else {
            faceFound = true
          }
        }
        //@ts-ignore
        else if (LegacyAvatarAccessory.includes(hat.item.key)) { 
          if (accessoryFound) {
            hat = null
          } else {
            accessoryFound = true
          }
        }
      }
      if (hand) {
        //@ts-ignore
        if (LegacyAvatarFace.includes(hand.item.key)) { 
          if (faceFound) {
            hand = null
          } else {
            faceFound = true
          }
        }
        //@ts-ignore
        else if (LegacyAvatarAccessory.includes(hand.item.key)) { 
          if (accessoryFound) {
            hand = null
          } else {
            accessoryFound = true
          }
        }
      }
      if (jewellery) {
        //@ts-ignore
        if (LegacyAvatarFace.includes(jewellery.item.key)) { 
          if (faceFound) {
            jewellery = null
          } else {
            faceFound = true
          }
        }
        //@ts-ignore
        else if (LegacyAvatarAccessory.includes(jewellery.item.key)) { 
          if (accessoryFound) {
            jewellery = null
          } else {
            accessoryFound = true
          }
        }
      }
      if (wings) {
        //@ts-ignore
        if (LegacyAvatarFace.includes(wings.item.key)) { 
          if (faceFound) {
            wings = null
          } else {
            faceFound = true
          }
        }
        //@ts-ignore
        else if (LegacyAvatarAccessory.includes(wings.item.key)) { 
          if (accessoryFound) {
            wings = null
          } else {
            accessoryFound = true
          }
        }
      }
      if (scarf) {
        //@ts-ignore
        if (LegacyAvatarFace.includes(scarf.item.key)) { 
          if (faceFound) {
            scarf = null
          } else {
            faceFound = true
          }
        }
        //@ts-ignore
        else if (LegacyAvatarAccessory.includes(scarf.item.key)) { 
          if (accessoryFound) {
            scarf = null
          } else {
            accessoryFound = true
          }
        }
      }

    }

    this.avatar = {
      ...this.avatar,
      hairColor,
      shirtColor1,
      shirtColor2,
      headwearColor,
      backgroundColor,

      hair: hair.item,
      eyes: eyes.item,
      mouth: mouth.item,
      background: background.item,
      shirt: shirt.item,

      facepaint: facepaint ? facepaint.item : undefined,
      facialhair: facialhair ? facialhair.item : undefined,
      face: face ? face.item : undefined,
      hat: hat ? hat.item : undefined,
      hand: hand ? hand.item : undefined,
      jewellery: jewellery ? jewellery.item : undefined,
      wings: wings ? wings.item : undefined,
      pet: pet ? pet.item : undefined,
      scarf: scarf ? scarf.item : undefined,
    }

    const elem = this.$refs.preview as HTMLElement
    if (elem && !!('Animation' in window)) {
      elem.animate([
        { transform: 'rotate(-20deg)', filter: 'blur(0.2em)', transformOrigin: 'center', offset: 0, easing: 'cubic-bezier(0.650, 1.650, 0.625, 0.790)' },
        { transform: 'rotate(-720deg)',filter: 'blur(0em)', transformOrigin: 'center', offset: 1, easing: 'cubic-bezier(0.650, 1.650, 0.625, 0.790)' },
      ], {
        duration: 600
      })
    }

    this.updateAvatarSrc()
    this.validateEquippedItems()
    this.updateAllColourableItems()
  }
}

