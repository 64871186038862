



















































































































































import _ from 'lodash'

import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import StringConversions from '@/mixins/StringConversions'
import { Api } from '@/edshed-common/api'
import { PagedResults, TableQuery } from '@/edshed-common/api/types'
import Modal from '@/edshed-common/components/Modal.vue'
import TagInput from '@/edshed-common/components/grammar/TagInput.vue'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import ComponentHelper from '@/mixins/ComponentHelper'
import { AvatarColor, AvatarItemInfo, AvatarItemSlot, AvatarLayers, defaultAvatarColors, _AvatarColor, _AvatarItemSlot } from '@/edshed-common/avatar/avatarItems'
import Avatar from '@/edshed-common/avatar/Avatar.vue'
import AvatarItem from '@/edshed-common/avatar/AvatarItem.vue'
import AvatarShop from '@/edshed-common/avatar/AvatarShop.vue'
import { AvatarRenderer, CanvasForUseInAvatarRendering } from '@/edshed-common/avatar/avatarRenderer'
import AvatarItemDetails from './components/AvatarItemDetails.vue'

@Component({
  components: {
    Modal,
    TagInput,
    Avatar,
    AvatarItem,
    AvatarItemDetails,
    AvatarShop
  }
})
export default class AvatarSettings extends mixins(ModalMixin, ComponentHelper) {
  private currentColourInput: HTMLInputElement | null = null

  private loading = true

  private avatarItemToEdit: AvatarItemInfo | null = null
  private avatarItemToEditOriginal: AvatarItemInfo | null = null
  private updateKey = 0

  private avatarItemData: PagedResults<AvatarItemInfo> = {
    total: 0,
    items: []
  }

  private selectedItemType: AvatarItemSlot = 'background'
  private currentPage = 1
  private avatarItemTableQuery: TableQuery = {
    dir: 'asc',
    skip: 0,
    sort: 'sort_order',
    take: 10,
    term: ''
  }

  private tableUpdateKey = 0
  private draggingEnabled = false
  private draggingRow: Partial<AvatarItemInfo> | null = null
  private draggingRowIndex = 0

  private avatarRenderer: AvatarRenderer | null = null
  private avatarItemPreviewRenderer!: AvatarRenderer

  private showAvatarShop = false

  async mounted () {
    this.selectedItemType = this.itemSlots[0]
    this.getAvatarItems()

    // const src = await Api.getDebugAvatar()

    const newCanvas = () => {
      const c = document.createElement('canvas') as HTMLCanvasElement
      const context = c.getContext('2d')!
      const canvas = c as CanvasForUseInAvatarRendering
      return { canvas, context }
    }

    const newImage = () => {
      return new Image()
    }

    this.avatarRenderer = new AvatarRenderer(newCanvas, newImage)
    this.avatarRenderer.isAuthoringMode = true
  }

  get itemSlots () {
    return _AvatarItemSlot.filter(x => x !== 'default')
  }

  get filteredItems () {
    return this.avatarItemData
  }

  get myAvatar () {
    const json = this.$store.state.user.avatar
    return JSON.parse(json)
  }

  private async getAvatarItems () {
    this.loading = true
    try {
      const res = await Api.browseAvatarItems(this.avatarItemTableQuery, this.selectedItemType)
      // const res = this.generateTableDataFromPurchasableItems(this.selectedItemType, this.avatarItemTableQuery)
      // const res = {items: exampleAvatarItems, total: exampleAvatarItems.length}
      this.avatarItemData = res
      this.loading = false
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }

  private onItemSaved () {
    this.avatarItemToEdit = null
    this.avatarItemToEditOriginal = null
    this.getAvatarItems()
  }

  private getColorSlotColor (slot: AvatarColor) {
    const color = defaultAvatarColors[slot]
    if (color) {
      return color.color
    }
    return '#fff'
  }

  // private generateTableDataFromPurchasableItems (selectedType: AvatarItemSlot, query: TableQuery): PagedResults<AvatarItemInfo> {
  //   const colors: AvatarColor[] = []
  //   if (selectedType === 'shirtType') {
  //     colors.push('')
  //     colors.push('skinColor')
  //     colors.push('shirtColor1')
  //     colors.push('shirtColor2')
  //   } else if (selectedType === 'hairType') {
  //     colors.push('hairColor')
  //   } else if (selectedType === 'eyes') {
  //     colors.push('')
  //     colors.push('eyeColor')
  //     colors.push('skinColor')
  //   }
  //   const arr = [...purchasableItems].filter(x => x.type === selectedType).map((x, index) => {
  //     return {
  //       id: index,
  //       name: x.name,
  //       key: x.key,
  //       cost: x.cost,
  //       type: x.type,
  //       layer: AvatarLayers.face,
  //       colors,
  //       hidden: false
  //     }
  //   }).sort((a, b) => {
  //     const ap = a[query.sort!]
  //     const bp = b[query.sort!]
  //     if (query.dir === 'asc') {
  //       return ap > bp ? 1 : -1
  //     } else {
  //       return ap > bp ? -1 : 1
  //     }
  //   }) as AvatarItemInfo[]
  //   const items = arr.slice(query.skip, (query.skip || 0) + (query.take || 10))
  //   return { items, total: arr.length }
  // }

  // private interceptColorClick (e: PointerEvent) {
  //   e.stopPropagation()
  //   const target = e.currentTarget as HTMLInputElement

  //   if (target.type === 'color') {
  //     e.preventDefault()
  //     this.onColourInputClick(target)
  //   } else {
  //     this.currentColourInput = null
  //   }
  // }

  // private editCurrentColorInput (e: PointerEvent) {
  //   e.stopPropagation()
  //   if (this.currentColourInput) {
  //     // @ts-ignore
  //     this.currentColourInput.showPicker()
  //   }
  //   this.currentColourInput = null
  // }

  // private onColourInputClick (elem: HTMLInputElement) {
  //   this.currentColourInput = elem
  // }

  // private onNewColour (e: InputEvent) {
  //   const elem = (e.currentTarget || e.target) as HTMLInputElement
  //   if (elem) {
  //     if (elem.dataset.type === '0') {
  //       const index = Math.max(...[...this.skinColors, ...this.newSkinColors].map(x => parseInt(x.key.replace('skin', ''))))
  //       this.newSkinColors.push({ color: elem.value, key: `skin${index + 1}` })
  //     }
  //     if (elem.dataset.type === '1') {
  //       const index = Math.max(...[...this.hairColors, ...this.newHairColors].map(x => parseInt(x.key.replace('hair', ''))))
  //       this.newHairColors.push({ color: elem.value, key: `hair${index + 1}` })
  //     }
  //     if (elem.dataset.type === '2') {
  //       const index = Math.max(...[...this.customColors, ...this.newCustomColors].map(x => parseInt(x.key.replace('col', ''))))
  //       this.newCustomColors.push({ color: elem.value, key: `col${index + 1}` })
  //     }
  //   }
  // }

  // private deleteColor (key: string, list) {
  //   if (list === 0) { this.newSkinColors = this.newSkinColors.filter(x => x.key !== key) }
  //   if (list === 1) { this.newHairColors = this.newHairColors.filter(x => x.key !== key) }
  //   if (list === 2) { this.newCustomColors = this.newCustomColors.filter(x => x.key !== key) }
  // }

  // private resetColors () {
  //   this.newSkinColors = []
  //   this.newHairColors = []
  //   this.newCustomColors = []
  // }

  // private saveColors () {
  //   try {

  //   } catch (error: unknown) {
  //     if (error instanceof Error) {
  //       this.alert({ title: error.name, message: error.message, console: error.stack })
  //     }
  //   }
  // }

  private onSearchKeyDown (e:KeyboardEvent) {
    console.log(e)
    if (e.key === 'Enter') {
      this.searchString()
    }
  }

  private searchString () {
    this.draggingEnabled = false
    this.getAvatarItems()
  }

  private clearSearch () {
    if (this.avatarItemTableQuery.term) {
      this.avatarItemTableQuery.term = ''
      this.getAvatarItems()
    }
  }

  private onChangeTab (value) {
    const type = this.itemSlots[value]
    this.selectedItemType = type
    this.resetTableQuery()
  }

  private resetTableQuery () {
    this.avatarItemTableQuery.skip = 0
    this.avatarItemTableQuery.term = ''
    this.currentPage = 1
    this.getAvatarItems()
  }

  private onTablePageChange (v: number) {
    this.currentPage = v
    this.avatarItemTableQuery.skip = this.avatarItemTableQuery.take! * (v - 1)
    this.getAvatarItems()
  }

  private onTableSort (field: string, order: 'asc' | 'desc') {
    this.avatarItemTableQuery.sort = field
    this.avatarItemTableQuery.dir = order
    this.getAvatarItems()
  }

  private async editItem (item: AvatarItemInfo) {
    if (this.hasUnsavedChanges()) {
      const c = await this.confirm({ title: 'Unsaved Changes', message: 'There are unsaved changes to the current item. Do you wish to discard them?' })
      if (!c) {
        return
      }
    }
    scrollTo({ top: 0 })
    this.avatarItemToEdit = _.cloneDeep(item)
    this.avatarItemToEditOriginal = item
    this.initEditItem()
  }

  private async deleteItem (item: AvatarItemInfo) {
    // console.error('I dont want to delete it, I dont know what might happen')
    try {
      const d = await this.deleteConfirm({ name: 'item' }, { title: 'DELETE ITEM', message: 'DELETING AN AVATAR ITEM DOES NOT DO ANYTHING CLEVER UNDER THE HOOD YET. \n DOING SO RISKS HAVING USERS WITH INCOMPLETE/CORRUPT AVATARS. \n THIS SHOULD ONLY BE DONE FOR NEWLY CREATED HIDDEN ITEMS THAT HAVE NOT YET BEEN RELEASED. CONTINUE?' })
      if (d) {
        const response = await Api.removeAvatarItem(item.key)
        console.log(response)
        this.getAvatarItems()
        this.$buefy.toast.open({
          message: 'Item deleted successfully',
          type: 'is-success',
          position: 'is-bottom',
          duration: 3000
        })
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }

  private hasUnsavedChanges () {
    if (!this.avatarItemToEdit) { return false }
    const { id, layers, new_image, image, colors, name, cost, type, key, hidden } = this.avatarItemToEdit
    const b = this.avatarItemToEditOriginal
    if (id === -1) { return true }
    if (new_image) { return true }
    if (!b) { return true }
    if (cost !== b.cost) { return true }
    if (name !== b.name) { return true }
    if (type !== b.type) { return true }
    if (key !== b.key) { return true }
    if (hidden !== b.hidden) { return true }
    if (colors.length !== b.colors.length) { return true }
    if (layers.layers.length !== b.layers.layers.length) { return true }
    if (!image && !!b.image) { return true }

    for (let i = 0; i < layers.layers.length; i++) {
      if (layers.layers[i].new_image) { return true }
      if (!layers.layers[i].image && !!b.layers.layers[i].image) { return true }
      if (layers.layers[i].layer !== b.layers.layers[i].layer) { return true }
      for (let j = 0; j < layers.layers[i].colors.length; j++) {
        const c = layers.layers[i].colors[j]
        const x = b.layers.layers[i]
        if (!x) { return true }
        if (c !== x.colors[j]) { return true }
      }
    }

    for (let i = 0; i < colors.length; i++) {
      if (colors[i] !== b.colors[i]) { return true }
    }

    return false
  }

  private async closeEditModal () {
    const c = await this.confirm({ title: 'Hold On', message: 'All unsaved changes will be lost. Do you wish to close the editor?' })
    if (!c) {
      return
    }
    this.avatarItemToEdit = null
    this.avatarItemToEditOriginal = null
    this.updateKey++
  }

  private newItem () {
    this.initEditItem()
    this.avatarItemToEditOriginal = null
    this.avatarItemToEdit = {
      sort_order: -1,
      id: -1,
      name: '',
      key: '',
      layers: { max_id: 0, layers: [{ id: 0, colors: [], layer: this.getSuggestedLayerForType(this.selectedItemType) }] },
      hidden: 1,
      type: this.selectedItemType,
      cost: 0,
      colors: [],
      image_version: 0
    }
  }

  private increaseSortOrder (item: AvatarItemInfo) {
    this.updateSortOrder(item.key, (item.sort_order || 0) + 1)
  }

  private decreaseSortOrder (item: AvatarItemInfo) {
    this.updateSortOrder(item.key, Math.max(0, (item.sort_order || 0) - 1))
  }

  private async updateSortOrder (key: string, n: number) {
    try {
      this.loading = true
      await Api.updateAvatarItemSortOrder(key, n)
      this.loading = false
      this.getAvatarItems()
      this.$buefy.toast.open({
        message: 'Item Sort Order Updated',
        type: 'is-success',
        position: 'is-bottom',
        duration: 2000
      })
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }

  private onSortOrderKeyDown (e: KeyboardEvent, item:AvatarItemInfo) {
    console.log('hello')
    if (e.key !== 'Enter') {
      return
    }
    e.preventDefault()

    const target = e.target as HTMLInputElement
    if (!target || target.value === undefined || target.value === null) {
      return
    }

    let value = parseInt(target.value)
    if (isNaN(value)) {
      value = item.sort_order
      console.error('not a number')
      return
    }
    this.updateSortOrder(item.key, value)
  }

  private toggleDraggable () {
    this.draggingEnabled = !this.draggingEnabled
    this.tableUpdateKey++
  }

  private dragstart (payload) {
    console.log(payload)
    this.draggingRow = payload.row
    this.draggingRowIndex = payload.index
    payload.event.dataTransfer.effectAllowed = 'copy'
  }

  private dragover (payload) {
    payload.event.dataTransfer.dropEffect = 'copy'
    payload.event.target.closest('tr').classList.add('is-selected')
    payload.event.preventDefault()
  }

  private dragleave (payload) {
    payload.event.target.closest('tr').classList.remove('is-selected')
    payload.event.preventDefault()
  }

  private drop (payload) {
    payload.event.target.closest('tr').classList.remove('is-selected')
    if (!this.draggingRow || !this.draggingRow.key) {
      return
    }
    let itemAtIndex = this.avatarItemData.items[payload.index]
    if (!itemAtIndex) {
      itemAtIndex = this.avatarItemData.items[this.avatarItemData.items.length - 1]
    }
    if (!itemAtIndex) { return }
    const newSortOrder = itemAtIndex.sort_order
    this.updateSortOrder(this.draggingRow.key, newSortOrder)
    this.draggingRow = null
    this.draggingRowIndex = -1
  }

  private getSuggestedLayerForType (type: AvatarItemSlot) {
    // return AvatarLayers.hairfront
    switch (type) {
      case 'hair': return AvatarLayers.hairfront
      case 'face': return AvatarLayers.face
      case 'hat': return AvatarLayers.hairfront
      case 'hand': return AvatarLayers.held
      case 'pet': return AvatarLayers.pet
      case 'shirt': return AvatarLayers.body
      case 'background': return AvatarLayers.background
      case 'eyes': return AvatarLayers.face
      case 'mouth': return AvatarLayers.face
      case 'scarf': return AvatarLayers.body
      case 'wings': return AvatarLayers.background
      case 'facepaint': return AvatarLayers.face
      case 'facialhair': return AvatarLayers.face
      case 'jewellery': return AvatarLayers.body
      case 'default': return AvatarLayers.face
      default: return AvatarLayers.head
    }
  }

  private initEditItem () {
    this.updateKey++
  }

  private openAvatarShop () {
    this.showAvatarShop = true
  }
}
